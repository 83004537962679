import React from "react"

import SEO from "components/seo"
import Link from 'components/link'

export default () => (
	<div className="page-404">
		<SEO title="404: Not found" />
		<h1>
			Page not found. 
		</h1>
		<p>
			The page you're looking for has either moved, or doesn't exist. Please <Link to={'/'}>click here</Link> to return to the home page.
		</p>
	</div>
)
